<!--
 * @Author: lbh
 * @Date: 2021-04-30 14:19:00
 * @LastEditors: lbh
 * @LastEditTime: 2021-05-11 16:08:09
 * @Description: 菜單
-->
<template>
  <div class="menu-box">
    <!-- 第一級 -->
    <div
      v-for="(item,index) in menus"
      :key="index"
      class="items"
    >
      <!-- 第一級內容 -->
      <div
        class="item"
        :class="item.active == active ? 'item-active' :''"
        @click="goUrl(item.go,item.type)"
      >
        <span v-text="item.name"></span>
        <i
          class="el-icon-arrow-down"
          v-if="item.child && item.child.length > 0"
          @click.stop="showAndClost(index)"
        ></i>
      </div>
      <!-- 第二級 -->
      <div
        class="child-box"
        :class="`child-box_${index}`"
        :style="`${item.active == active ? `height:auto !important;` : ``}`"
      >
        <div
          class="child"
          v-for="(ite,ind) in item.child"
          :key="ind"
          v-text="ite.name"
          :class="ite.active == childActive ? 'child-active' : ''"
          @click="goUrl(ite.go)"
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
import jquery from "jquery";
export default {
  name: 'self-menu',
  props: {
    menus: {
      default: () => {
        return []
      }
    },
    active: {
      default: () => {
        return ''
      }
    },
    childActive: {
      default: () => {
        return ''
      }
    },
  },
  data () {
    return {
    }
  },
  mounted () {

  },
  methods: {
    goUrl (t, type = '') {
      if (type == 'href') {
        window.location.href = t;
      } else {
        let pathname = location.pathname;
        if (pathname.indexOf(t) == -1 || (t == '/' && pathname != t)) {
          this.$router.push({
            path: t
          });
        }
      }

    },
    showAndClost (index) {
      let childbox = jquery(`.child-box_${index}`);
      if (childbox[0].offsetHeight > 0) {
        childbox.stop();
        childbox.animate({
          height: 0
        })
      } else {
        let childs = childbox.children();
        let h = 0;
        for (let i = 0; i < childs.length; i++) {
          h += childs[i].offsetHeight;
        }
        childbox.stop();
        childbox.animate({
          height: h
        })
      }

    }
  },
  watch: {

  }
}
</script>
<style lang="less" scoped>
.menu-box {
  padding: 0 10px;
  font-size: 16px;
  .items {
    .item-active {
      color: #980000;
    }
    .item {
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #ddd;
      &:hover {
        background-color: #ddd;
        color: #980000;
      }
    }
    .child-box {
      padding-left: 20px;
      height: 0;
      overflow: hidden;
      .child-active {
        color: #980000;
      }
      .child {
        padding: 10px 0;
        border-bottom: 1px solid #ddd;
        &:hover {
          background-color: #ddd;
          color: #980000;
        }
      }
    }
  }
}
</style>