<!--
 * @Author: lbh
 * @Date: 2021-04-21 18:23:05
 * @LastEditors: lbh
 * @LastEditTime: 2022-06-21 17:30:34
 * @Description: file content
-->
<template>
  <div class="self-footer-box">
    <div
      class="self-footer"
      :class="type == '900' ? 'self-footer_1' : type == '600' ? 'self-footer_2' : ''"
    >
      <div class="items_1">
        <h4>公司信息</h4>
        <div>薑軟件有限公司 Gingersoft Limited</div>
        <div v-if="!isRicepon">聯繫電話(香港): +852 8200 2022</div>
        <div>
          郵箱：
          <a
            href="mailto:info@gingersoft.com.hk"
            target="_blank"
          >info@gingersoft.com.hk</a>
        </div>
        <div v-if="isRicepon">
          單位地址：九龍青山道479-479A 麗昌工廠大廈6樓603B室
        </div>
        <div v-else>單位地址：九龍青山道700號時運中心5樓4室</div>
      </div>
      <div
        class="items_1"
        v-if="!isRicepon"
      >
        <h4>我們的社交平台</h4>
        <div class="item">
          <a
            class="pointer-hover"
            href="https://www.facebook.com/gspos"
          >
            <img src="../../assets/default/Facebook@2x.png" />
            Facebook
          </a>
        </div>
      </div>
      <div
        class="items_2"
        v-if="isRicepon"
      >
        <h4>公司業務</h4>
        <div class="item">
          <div
            class="pointer-hover"
            @click="goUrl('/gspos')"
          >GSPOS落單系統</div>
          <div
            class="pointer-hover"
            @click="goUrl('/gsa')"
          >手機點餐系統</div>
        </div>
        <div class="item">
          <div
            class="pointer-hover"
            @click="goUrl('/gsq')"
          >自取排隊系統</div>
          <div
            class="pointer-hover"
            @click="goUrl('/reservation')"
          >網上訂座</div>
        </div>
        <div class="item">
          <div
            class="pointer-hover"
            @click="goUrl('/takeOutOrPickUp')"
          >外賣</div>
          <div
            class="pointer-hover"
            @click="goUrl('/qrcode')"
          >掃碼點餐</div>
        </div>
        <div class="item">
          <div
            class="pointer-hover"
            @click="goUrl('/lineUp')"
          >排隊取號</div>
          <div
            class="pointer-hover"
            @click="goUrl('/vip')"
          >會員系統</div>
        </div>
        <div class="item">
          <div
            class="pointer-hover"
            @click="goUrl('/game')"
          >遊戲及營銷</div>
        </div>
      </div>
      <div
        class="items_3"
        v-if="isRicepon"
      >
        <h4>合作夥伴</h4>
        <div>
          <a
            href="https://www.octopus.com.hk"
            target="_blank"
            rel="noopener noreferrer"
          >八達通</a>
        </div>
        <div>openrice</div>
      </div>
    </div>
    <div
      class="suoshu"
      v-if="isNews"
    >
      CopyRight 2022-2040 深圳市姜科網絡科技有限公司 备案号:<a href="https://beian.miit.gov.cn">粤ICP备2022075036号-1</a>
    </div>
  </div>
</template>
<script>
import util from "@/js/utils/util";
export default {
  name: 'self-footer',
  data () {
    return {
      isRicepon: true,
      isNews: false
    }
  },
  mounted () {
    this.isRicepon = util.isRicepon();
    if (location.origin.indexOf('.com.cn') > -1) {
      this.isNews = true;
    }

  },
  created () { },
  methods: {
    goUrl (t) {
      let pathname = location.pathname;
      if (pathname.indexOf(t) == -1 || (t == '/' && pathname != t)) {
        this.$router.push({
          path: t
        });
      }
    }
  },
  props: {
    type: {
      default: '1000'
    }
  }
}
</script>
<style lang="less" scoped>
.self-footer_1 {
  padding: 0 20px 50px !important;
}
.self-footer_2 {
  padding: 0 20px 50px !important;
  .items_2 {
    margin-left: 0 !important;
  }
}
.self-footer {
  display: flex;
  background-color: #000;
  padding: 0 90px 55px 90px;
  color: #fff;
  flex-wrap: wrap;
  .items_1 {
    min-width: 40%;
    margin-top: 55px;
    margin-right: 30px;
    a {
      &:hover {
        color: #980000;
      }
      img {
        width: 40px;
        margin-right: 10px;
      }
    }
    h4 {
      margin-bottom: 25px;
    }
    div {
      padding: 5px 0;
    }
  }
  .items_2 {
    min-width: 30%;
    margin-top: 55px;
    margin-right: 30px;
    margin-left: auto;
    h4 {
      margin-bottom: 25px;
    }
    .item {
      padding: 5px 0;
    }
  }
  .items_3 {
    flex-grow: 1;
    margin-top: 55px;
    margin-left: auto;
    h4 {
      margin-bottom: 25px;
    }
    div {
      padding: 5px 0;
      a:hover {
        color: #980000;
      }
    }
  }

  a {
    color: #fff;
    text-decoration: none;
  }
  .item {
    display: flex;
    div:first-child {
      width: 150px;
    }
    div:hover {
      color: #900000;
    }
  }
}
.suoshu {
  background-color: #000;
  color: #fff;
  text-align: center;
  font-size: 14px;
  padding-bottom: 30px;
  a {
    color: #fff;
    text-decoration: none;
  }
}
</style>